<template>
  <div>
    <v-btn
      block
      v-if="enrollable.canEnroll || enrollable.enrolled"
      @click="toggleEnrollment"
      class="white--text"
      :disabled="!enrollable.canEnroll"
      :loading="enrolling"
      :color="enrollable.enrolled ? 'danger' : 'primary'"
    >
      {{ enrollable.enrolled ? $t("unenroll") : $t("enroll") }}
    </v-btn>
    <v-alert
      v-if="enrollable.enrollments_enabled && !enrollable.canEnroll"
      dense
      color="danger"
      dark
      class="text-center mt-2"
      >{{ $t(`enrollable.enrollments_status.${enrollable.enrollments_status}`) }}</v-alert
    >
  </div>
</template>

<script>
import gql from "graphql-tag";
import { ucFirst } from "../../../apollo/helpers";

export const ENROLL_BUTTON_FRAGMENT = gql`
  fragment enrollButton on Enrollable {
    id
    enrolled
    canEnroll
    enrollments_enabled
    enrollments_status
  }
`;

export default {
  name: "EnrollButton",
  props: {
    enrollable: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      enrolling: false,
    };
  },
  methods: {
    toggleEnrollment() {
      const mutationName = this.enrollable.enrolled ? "unenrollFrom" : "enrollTo";
      const typename = this.enrollable.__typename;
      const model = typename.toLowerCase();
      this.enrolling = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ${ucFirst(mutationName)}${typename}($enrollable_id: ID!) {
              ${mutationName}${typename}(${model}_id: $enrollable_id) {
                id
                ...enrollButton
              }
            }
            ${ENROLL_BUTTON_FRAGMENT}
          `,
          variables: {
            enrollable_id: this.enrollable.id,
          },
        })
        .then(() => {
          this.enrolling = false;
        });
    },
  },
};
</script>
