<template>
  <base-form
    model="payment"
    @save="payment_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.payment.loading"
    :currentValue="payment"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";

export const PAYMENT_FRAGMENT = gql`
  fragment payment on Payment {
    id
    date
    amount
    type
    payment_method
    description
    notes
    studentYear {
      id
      name
      surname
      full_name
      student {
        id
        user {
          id
        }
      }
    }
    payable {
      __typename
      ... on Event {
        id
        name
        course {
          id
          name
        }
      }
      ... on Course {
        id
        name
      }
    }
  }
`;

export default {
  components: { BaseForm },
  name: "PaymentForm",
  props: {
    payment_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    payment_id(val) {
      if (val == null) this.payment = {};
    },
  },

  apollo: {
    payment: {
      query: gql`
        query Payment($id: ID!) {
          payment(id: $id) {
            ...payment
          }
        }
        ${PAYMENT_FRAGMENT}
      `,
      variables() {
        return { id: this.payment_id };
      },
      skip() {
        return this.payment_id == null;
      },
      update: (data) => {
        if (data.payment.type == "course_enrollment") data.payment.course = data.payment.payable;
        else if (data.payment.type == "event_subscription") {
          data.payment.event = data.payment.payable;
          data.payment.course = data.payment.payable.course;
        }
        return data.payment;
      },
    },
  },

  data() {
    return {
      newValue: {},
      payment: {},
      saving: false,
      fields: [
        {
          name: "amount",
          type: "number",
          validation: {
            required,
          },
          bind: {
            step: 0.01,
            "append-icon": "mdi-currency-eur",
          },
        },
        {
          name: "date",
          type: "date",
          validation: {
            required,
          },
        },
        {
          name: "type",
          type: "autocomplete",
          items: ["tuition", "deposit", "course_enrollment", "event_subscription", "refund", "client_contribution"].map(
            (value) => ({
              text: this.$t(`options.payment.type.${value}`),
              value,
            })
          ),
          validation: {
            required,
          },
          isVisible: () => !this.baseItem.type,
        },
        {
          name: "payment_method",
          type: "autocomplete",
          items: ["bank_trasfer", "card", "cash", "satispay", "other"].map((value) => ({
            text: this.$t(`options.payment.payment_method.${value}`),
            value,
          })),
          validation: {
            required,
          },
          isVisible: () => !this.baseItem.type,
        },
        {
          name: "description",
        },
        {
          name: "course",
          label: this.$t("course.name"),
          type: "query",
          path: "courses",
          query: gql`
            query Courses($year_id: ID!, $area_id: ID!, $has_payments: Boolean) {
              courses(area_id: $area_id, year_id: $year_id, has_payments: $has_payments) {
                id
                name
              }
            }
          `,
          variables: (payment) => {
            if (payment.type == "course_enrollment")
              return {
                area_id: this.baseItem.studentYear.residence.area.id,
                year_id: this.baseItem.studentYear.year.id,
                has_payments: true,
              };
            else
              return {
                area_id: this.baseItem.studentYear.residence.area.id,
                year_id: this.baseItem.studentYear.year.id,
              };
          },
          validation: {
            required,
          },
          isVisible: ({ type }) =>
            !["course_enrollment", "event_subscription"].includes(this.baseItem.type) &&
            (type == "course_enrollment" || type == "event_subscription"),
        },
        {
          name: "studentYear",
          label: this.$t("student.name"),
          type: "query",
          path: "course.reachedStudentYears",
          text: "full_name",
          query: gql`
            query CourseEvents($id: ID!) {
              course(id: $id) {
                id
                reachedStudentYears {
                  id
                  full_name
                }
              }
            }
          `,
          variables: () => ({ id: this.baseItem.course_id }),
          validation: {
            required,
          },
          isVisible: () => !this.baseItem.studentYear,
        },
        {
          name: "event",
          label: this.$t("event.name"),
          type: "query",
          path: "course.events",
          query: gql`
            query CourseEvents($id: ID!) {
              course(id: $id) {
                id
                events(has_payments: true) {
                  id
                  name
                }
              }
            }
          `,
          skip: (payment) => !payment.course,
          variables: (payment) => ({ id: payment.course?.id }),
          validation: {
            required,
          },
          isVisible: ({ type }) => !["event_subscription"].includes(this.baseItem.type) && type == "event_subscription",
        },
        {
          name: "notes",
          cols: 12,
          type: "textarea",
        },
      ],
    };
  },

  computed: {
    mutationInput() {
      // eslint-disable-next-line no-unused-vars
      let { __typename, course_id, event_id, payable_id, ...input } = this.newValue;

      if (input.type == "course_enrollment") {
        input.payable = {
          connect: {
            id: course_id || this.payment.payable?.id,
            type: "App\\Models\\Course",
          },
        };
      } else if (input.type == "event_subscription") {
        input.payable = {
          connect: {
            id: event_id || this.payment.payable?.id,
            type: "App\\Models\\Event",
          },
        };
      } else if (input.type != null) {
        input.payable = {
          disconnect: true,
        };
      }
      return input;
    },
  },

  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Payment",
            fragment: PAYMENT_FRAGMENT,
            fragment_name: "payment",
          }),
          variables: {
            id: this.payment_id,
            input: this.mutationInput,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Payment",
            fragment: PAYMENT_FRAGMENT,
            fragment_name: "payment",
          }),
          variables: {
            input: this.mutationInput,
          },
        })
        .then(({ data: { createPayment } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createPayment);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
