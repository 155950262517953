<template>
  <v-container>
    <v-img v-if="!loading" :src="currentUrl" />
    <v-skeleton-loader v-else type="image" />
  </v-container>
</template>

<script>
import { EVENT_SIGN_SUBSCRIPTION } from "../../graphql/subscriptions";
import { playSound } from "@/maia/utils.js";
import gql from "graphql-tag";

export default {
  name: "EventQR",
  props: {
    event_id: { required: true },
  },
  data() {
    return {
      session: Math.floor(Math.random() * 10000),
      currentSign: null,
    };
  },
  apollo: {
    event: {
      query: gql`
        query EventQR($id: ID!, $session: Int!) {
          event(id: $id) {
            id
            qr_url(session: $session)
          }
        }
      `,
      variables() {
        return {
          id: this.event_id,
          session: this.session,
        };
      },
    },
    $subscribe: {
      newSign: {
        query: EVENT_SIGN_SUBSCRIPTION,
        variables() {
          return {
            input: { event_id: this.event_id, session: this.session },
          };
        },
        result({ data }) {
          const newSign = data.newSign;
          if (!newSign) return;
          let timeout = null;
          if (!newSign.verified) {
            this.currentSign = newSign;
            timeout = setTimeout(() => (this.currentSign = null), 10000);
          } else {
            if (newSign.id === this.currentSign?.id) {
              if (timeout) clearTimeout(timeout);
              this.currentSign = null;
              playSound();
            }
            this.$emit("newSign", newSign);
          }
        },
      },
    },
  },
  computed: {
    currentUrl() {
      return this.currentSign?.qr_url || this.event.qr_url;
    },
    loading() {
      return this.$apollo.queries.event.loading;
    },
  },
};
</script>
