<template>
  <base-table
    :title="title"
    :items="payable.payments"
    :headers="headers"
    v-bind="tableProps"
    :viewOnly="!payable.canManagePayments"
    @deleteItem="deletePayment"
    :itemRoute="itemRoute"
  >
    <template v-slot:type="{ item }">
      {{ $t(`options.payment.type.${item.type}`) }}
    </template>
    <template v-slot:payment_method="{ item }">
      {{ item.payment_method ? $t(`options.payment.payment_method.${item.payment_method}`) : "" }}
    </template>
    <template v-slot:form="{ selected_id, close, duplicating }">
      <payment-form
        :payment_id="selected_id"
        :baseItem="baseItem"
        :duplicating="duplicating"
        @cancel="close"
        @save="(payment) => paymentUpdated(payment, close)"
        @created="(payment) => paymentCreated(payment, close)"
      />
    </template>
  </base-table>
</template>

<script>
import BaseTable from "./BaseTable.vue";
import PaymentForm from "../forms/PaymentForm.vue";
import gql from "graphql-tag";
import moment from "moment";
import { addToCacheFragmentArray, removeFromCacheFragmentArray } from "@/apollo/helpers";

const PAYMENTS_TABLE_ROW_FRAGMENT = gql`
  fragment paymentsTableRow on Payment {
    id
    amount
    date
    type
    payment_method
    description
  }
`;

export const STUDENT_YEAR_PAYMENTS_TABLE_FRAGMENT = gql`
  fragment studentYearPaymentsTable on StudentYear {
    id
    canManagePayments
    year {
      id
    }
    payments {
      payable {
        ... on PayableInterface {
          id
        }
      }
      ...paymentsTableRow
    }
  }
  ${PAYMENTS_TABLE_ROW_FRAGMENT}
`;

export const PAYABLE_PAYMENTS_TABLE_FRAGMENT = gql`
  fragment payablePaymentsTable on PayableInterface {
    id
    ... on Event {
      id
      course {
        id
      }
    }
    canManagePayments
    payments {
      id
      studentYear {
        id
        name
        surname
        student {
          id
          user {
            id
          }
        }
      }
      ...paymentsTableRow
    }
  }
  ${PAYMENTS_TABLE_ROW_FRAGMENT}
`;

export default {
  components: { BaseTable, PaymentForm },
  name: "PaymentsTable",
  props: {
    title: String,
    payable: Object,
    baseItem: { type: Object, default: () => ({}) },
    tableProps: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      user_headers: [
        { value: "amount", text: this.$t("payment.amount"), type: "currency" },
        { value: "date", text: this.$t("payment.date"), type: "date" },
        { value: "type", text: this.$t("payment.type"), type: "template" },
        { value: "payment_method", text: this.$t("payment.payment_method"), type: "template" },
        { value: "description", text: this.$t("payment.description") },
      ],

      resource_headers: [
        { value: "studentYear.surname", text: this.$t("user.surname") },
        { value: "studentYear.name", text: this.$t("user.name") },
        { value: "amount", text: this.$t("payment.amount"), type: "currency" },
        { value: "date", text: this.$t("payment.date"), type: "date" },
        { value: "description", text: this.$t("payment.description") },
      ],

      moment,
    };
  },

  computed: {
    headers() {
      if (this.baseItem.type) return this.resource_headers;
      else return this.user_headers;
    },

    fragmentInfo() {
      if (this.payable.__typename === "StudentYear")
        return { fragment: STUDENT_YEAR_PAYMENTS_TABLE_FRAGMENT, fragmentName: "studentYearPaymentsTable" };
      else return { fragment: PAYABLE_PAYMENTS_TABLE_FRAGMENT, fragmentName: "payablePaymentsTable" };
    },
  },

  methods: {
    itemRoute(item) {
      if (this.payable.__typename === "StudentYear") {
        return (
          item.payable && {
            name: item.payable.__typename,
            params: { id: item.payable.id },
          }
        );
      } else
        return {
          name: "UserProfileYearTab",
          hash: "#student-year",
          params: { student_year_id: item.studentYear.id, id: item.studentYear.student.user.id, tab: "4" },
        };
    },
    paymentCreated(newElement, close) {
      addToCacheFragmentArray(this.$apollo.provider.defaultClient, {
        fragment_info: {
          ...this.fragmentInfo,
          id: `${this.payable.__typename}:${this.payable.id}`,
        },
        array_path: "payments",
        newElement,
      });
      this.$emit("paymentCreated", newElement);
      close();
    },

    deletePayment({ id }) {
      this.$apollo.mutate({
        mutation: gql`
          mutation deletePayment($id: ID!) {
            deletePayment(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
        update: (store, { data: { deletePayment } }) =>
          removeFromCacheFragmentArray(store, {
            fragment_info: {
              ...this.fragmentInfo,
              id: `${this.payable.__typename}:${this.payable.id}`,
            },
            array_path: "payments",
            id: deletePayment.id,
          }),
      });
    },

    paymentUpdated(payment, close) {
      this.$emit("paymentUpdated", payment);
      close();
    },
  },
};
</script>
