<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col
        class="d-flex align-center justify-center justify-sm-end"
        cols="12"
        sm="auto"
        v-if="!loading && (event.previousEvent || event.nextEvent)"
      >
        <v-btn
          small
          link
          text
          v-if="event.previousEvent"
          :to="{
            name: 'Event',
            params: { id: event.previousEvent.id },
          }"
        >
          <v-icon>mdi-arrow-left-circle-outline</v-icon>
          <span class="ml-1">{{ event.previousEvent.name }}</span>
        </v-btn>
        <v-btn small link text v-else disabled>
          <v-icon>mdi-arrow-left-circle-outline</v-icon>
        </v-btn>

        <v-spacer class="d-sm-none" />

        <v-btn
          small
          link
          text
          class="ml-2"
          v-if="event.nextEvent"
          :to="{
            name: 'Event',
            params: { id: event.nextEvent.id },
          }"
        >
          <span class="mr-1">{{ event.nextEvent.name }}</span>
          <v-icon>mdi-arrow-right-circle-outline</v-icon>
        </v-btn>
        <v-btn class="ml-2" small link text v-else disabled>
          <v-icon>mdi-arrow-right-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="5" lg="4" cols="12">
        <edit-card
          hideEmptyFields
          :expandable="!isStudent"
          v-if="!loading"
          :info="info"
          model="event"
          :item="event"
        >
          <template v-slot:title
            ><back-button :to="{ name: 'Course', params: { id: event.course.id } }" :text="event.name"
          /></template>
          <template #top v-if="event.canAddPresences">
            <event-qr :event_id="$route.params.id" @newSign="newSign" />
            <v-list-item v-if="event.notes">
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t("fields.notes") }}
                </v-list-item-subtitle>
                <v-list-item-title class="notes">{{ event.notes }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #bottom>
            <enroll-button :enrollable="event"></enroll-button>
            <payment-link-button :payable="event"></payment-link-button>
          </template>

          <template v-slot:form="{ close }">
            <event-form @cancel="close" @save="close" :event_id="event.id" />
          </template>
        </edit-card>
        <v-skeleton-loader type="card" v-else rounded="lg" />
      </v-col>

      <v-col v-if="me" sm="7" lg="8" cols="12">
        <div v-if="!isStudent">
          <v-card v-if="!loading" rounded="lg" class="overflow-hidden mb-4">
            <event-presents-table :presents="event.presents" :baseItem="{ event }" />
          </v-card>
          <v-skeleton-loader v-else type="table" />
          <v-card v-if="!loading" rounded="lg" class="overflow-hidden mb-4">
            <enrolled-table :enrolled="event.enrolledStudentYears" :baseItem="{ enrollable: event }" />
          </v-card>
          <v-card
            class="overflow-hidden mb-4"
            rounded="lg"
            v-if="!loading && event.canViewPayments && event.has_payments"
          >
            <v-skeleton-loader v-if="$apollo.queries.payable.loading" type="table" />
            <payments-table
              v-else
              :payable="payable"
              :title="$tc('models.payment', 2)"
              :tableProps="{ showTotal: true }"
              :baseItem="{ event_id: event.id, course_id: event.course.id, type: 'event_subscription' }"
            ></payments-table>
          </v-card>
        </div>
        <div v-else>
          <my-review
            v-if="!loading && event.canCreateReviews"
            :review="event.myReview"
            :reviewable="event"
            @created="reviewCreated"
            @deleted="reviewDeleted"
          />

          <v-skeleton-loader v-if="loading" type="table" />
        </div>
        <reviews-list @reviewDeleted="reviewDeleted" v-if="!loading" :reviews="event.reviews" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.notes {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
</style>

<script>
import { addToCacheArray, removeFromCacheArray, ucFirst, updateModel } from "../apollo/helpers";
import EditCard from "../components/base/EditCard.vue";
import BackButton from "../components/BackButton.vue";
import gql from "graphql-tag";
import EventForm from "../components/forms/EventForm.vue";
import EventQr from "../components/qr/EventQR.vue";
import MyReview from "../components/education/reviews/MyReview.vue";
import ReviewsList from "../components/education/reviews/ReviewsList.vue";
import { REVIEW_FRAGMENT } from "../components/education/reviews/Review.vue";
import EventPresentsTable, { PRESENTS_TABLE_FRAGMENT } from "../components/tables/EventPresentsTable.vue";
import EnrolledTable, { ENROLLABLE_ENROLLED_FRAGMENT } from "../components/tables/EnrolledTable.vue";
import EnrollButton, { ENROLL_BUTTON_FRAGMENT } from "../components/education/enrollments/EnrollButton.vue";
import PaymentsTable, { PAYABLE_PAYMENTS_TABLE_FRAGMENT } from "../components/tables/PaymentsTable.vue";
import PaymentLinkButton, {
  PAYMENT_LINK_FRAGMENT,
} from "@/components/education/payments/PaymentLinkButton.vue";

const BASE_EVENT_FRAGMENT = gql`
  fragment baseEvent on Event {
    id
    name
    start_timestamp
    end_timestamp
    mode
    argument
    has_payments
    canViewPayments

    enrollments_start
    enrollments_deadline
    enrollments_max_number

    ...enrollButton

    teacher
    organizer
    link
    rating

    canEnrollAny

    canUpdate
    canAddPresences
    canCreateReviews
    delegate {
      id
      complete_name
    }
    room {
      id
      name
    }
    course {
      id
      name
      year {
        id
      }
    }

    reviews {
      ...review
    }

    nextEvent {
      id
      name
    }

    previousEvent {
      id
      name
    }
  }
  ${REVIEW_FRAGMENT}
  ${ENROLL_BUTTON_FRAGMENT}
`;

const STUDENT_EVENT_QUERY = gql`
  query StudentEvent($id: ID!) {
    event(id: $id) {
      ...baseEvent
      ...paymentLink
      myReview {
        ...review
      }
    }
  }
  ${REVIEW_FRAGMENT}
  ${BASE_EVENT_FRAGMENT}
  ${PAYMENT_LINK_FRAGMENT}
`;

const ADMIN_EVENT_QUERY = gql`
  query AdminEvent($id: ID!, $session: Int!) {
    event(id: $id) {
      ...baseEvent
      notes
      type
      qr_url(session: $session)
      enrolledStudentYears {
        id
      }

      ...enrolledTable

      presents {
        ...presentsTable
      }
      report: file(name: "report") {
        id
        name
        link
      }
    }
  }
  ${BASE_EVENT_FRAGMENT}
  ${PRESENTS_TABLE_FRAGMENT}
  ${ENROLLABLE_ENROLLED_FRAGMENT}
`;

const EVENT_QUERY = gql`
  query Event($id: ID!, $session: Int!) {
    event(id: $id) {
      ...baseEvent
      type
      qr_url(session: $session)
      presents {
        ...presentsTable
      }

      ...enrolledTable
    }
  }
  ${BASE_EVENT_FRAGMENT}
  ${PRESENTS_TABLE_FRAGMENT}
  ${ENROLLABLE_ENROLLED_FRAGMENT}
`;
export default {
  name: "Event",

  components: {
    EditCard,
    BackButton,
    EventForm,
    EventQr,
    MyReview,
    ReviewsList,
    EventPresentsTable,
    EnrolledTable,
    EnrollButton,
    PaymentLinkButton,
    PaymentsTable,
  },

  data() {
    return {
      session: Math.floor(Math.random() * 10000),
      currentSign: null,
      editing: false,
      enrolling: false,
      payments: [],
    };
  },

  watch: {
    $route(to, from) {
      if (to.params.id != from.params.id) this.editing = false;
    },
  },

  apollo: {
    me: gql`
      query Me {
        me {
          id
          type
          student {
            id
            studentYears {
              id
            }
          }
        }
      }
    `,
    event: {
      query() {
        return this.isStudent ? STUDENT_EVENT_QUERY : this.isCourseAdmin ? EVENT_QUERY : ADMIN_EVENT_QUERY;
      },
      variables() {
        return {
          id: this.$route.params.id,
          session: this.session,
        };
      },
      skip() {
        return this.$apollo.queries.me.loading;
      },
    },
    payable: {
      query: gql`
        query EventPayments($id: ID!) {
          event(id: $id) {
            id
            ...payablePaymentsTable
          }
        }
        ${PAYABLE_PAYMENTS_TABLE_FRAGMENT}
      `,
      update: (data) => data.event,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return this.loading || !this.event.has_payments || !this.event.canViewPayments;
      },
    },
  },
  computed: {
    isStudent() {
      return this.me.type == "student";
    },

    isCourseAdmin() {
      return this.me.type == "course_admin";
    },

    loading() {
      return this.$apollo.queries.event.loading || this.$apollo.queries.me.loading;
    },

    currentUrl() {
      return this.currentSign?.qr_url || this.event.qr_url;
    },

    query() {
      return {
        query: this.isStudent ? STUDENT_EVENT_QUERY : this.isCourseAdmin ? EVENT_QUERY : ADMIN_EVENT_QUERY,
        variables: {
          id: this.$route.params.id,
          session: this.session,
        },
      };
    },

    info() {
      return [
        { field: "name", cols: 12 },
        { field: "argument", cols: 12 },
        { field: "start_timestamp", type: "datetime", cols: 12 },
        { field: "end_timestamp", type: "datetime", cols: 12 },
        { field: "mode", type: "autocomplete", cols: 12 },
        { field: "subscription_start", type: "datetime", cols: 12 },
        { field: "subscription_deadline", type: "datetime", cols: 12 },
        { field: "max_enrolled", type: "number", cols: 12 },
        { field: "teacher", cols: 12 },
        { field: "organizer", cols: 12 },
        { field: "link", type: "link", cols: 12 },
        { field: "type", type: "autocomplete", cols: 12 },
        { field: "delegate.complete_name", label: this.$t("event.delegate"), cols: 12 },
        { field: "room.name", label: this.$t("room.name"), cols: 12 },
        { field: "course.name", label: this.$t("course.name"), cols: 12 },
        { field: "report", label: this.$t("event.report"), type: "file", cols: 12 },
        { field: "rating", cols: 12, type: "rating" },
      ];
    },
  },

  methods: {
    newSign(newSign) {
      let { studentYear, ...signInfo } = newSign;

      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: this.query,
        queryField: "event.presents",
        newElement: {
          ...studentYear,
          pivot: signInfo,
        },
        unshift: true,
      });
    },

    updateEvent(item) {
      updateModel(this.$apollo, {
        item,
        model: "Event",
      });
    },

    reviewDeleted({ id }) {
      this.editing = false;
      const store = this.$apollo.provider.defaultClient;

      removeFromCacheArray(store, {
        query: this.query,
        queryField: "event.reviews",
        id,
      });
    },

    reviewCreated(newElement) {
      this.editing = false;
      const store = this.$apollo.provider.defaultClient;
      addToCacheArray(store, {
        query: this.query,
        queryField: "event.reviews",
        newElement,
        orderBy: { created_at: "desc" },
      });
    },

    subscriptionChanger() {
      const mutationName = this.event.enrolled ? "unsubscribeMe" : "subscribeMe";
      this.enrolling = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ${ucFirst(mutationName)}($event_id: ID!) {
              ${mutationName}(event_id: $event_id) {
                id
                enrolled
              }
            }
          `,
          variables: {
            event_id: this.$route.params.id,
          },
        })
        .then(() => {
          this.enrolling = false;
        })
        .catch((error) => {
          console.error(error);
          this.enrolling = false;
        });
    },
  },
};
</script>
