<template>
  <v-card rounded="lg">
    <v-card-title class="text-wrap text-uppercase text-h6 text-center text-md-left" style="word-break: break-word">
      <slot name="title">{{ title }}</slot>
      <v-spacer></v-spacer>
      <slot name="action"></slot>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <slot name="top" />

      <v-row align="center" justify="space-between" no-gutters>
        <slot name="left" />
        <v-col>
          <v-expansion-panels v-model="panel" flat accordion>
            <v-expansion-panel :key="0">
              <v-expansion-panel-header :class="{ 'd-none': !expandable }" hide-actions>
                <v-row align="center" no-gutters>
                  <v-col class="text-center"><v-icon>mdi-dots-horizontal</v-icon></v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="visibleInfo.length > 0" :class="{ 'pb-0': !expandable }">
                <v-list :class="['d-flex', 'flex-wrap', 'pt-0']">
                  <v-col
                    :class="[{ 'flex-grow-1 flex-shrink-0 grow': grow }, 'px-1 py-0']"
                    cols="12"
                    :sm="info.cols || 4"
                    v-for="info in visibleInfo"
                    :key="info.field"
                  >
                    <v-divider class="my-4" v-if="info.divider" />
                    <v-spacer v-else-if="info.spacer" />
                    <v-alert class="my-0" :icon="false" dense outlined type="info" v-else-if="info.highlight">
                      <model-info class="text-center" v-bind="info" :model="model" :value="_get(item, info.field)" />
                    </v-alert>
                    <model-info
                      v-else
                      class="text-center"
                      v-bind="info"
                      :model="model"
                      :value="_get(item, info.field)"
                    />
                  </v-col>
                </v-list>
              </v-expansion-panel-content>
              <span class="d-flex justify-center" v-else> {{ $t("no_data") }} </span>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <slot name="right" />
      </v-row>

      <slot name="bottom" />

      <v-dialog v-model="dialog" max-width="800px" persistent>
        <slot name="form" :close="close" />
      </v-dialog>
    </v-card-text>

    <v-divider v-if="item.canUpdate"></v-divider>
    <v-card-actions v-if="item.canUpdate" class="d-flex justify-end">
      <slot name="actions"></slot>
      <v-btn color="primary" @click="dialog = true">{{ $t("dialog.edit") }} </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { extractIds, snakeToCamel, ucFirst } from "../../apollo/helpers";

import _get from "lodash/get";
import ModelInfo from "./ModelInfo.vue";

export default {
  name: "EditCard",
  components: { ModelInfo },
  props: {
    title: {
      type: String,
      default: "",
    },
    model: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    info: {
      type: Array,
      required: true,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    hideEmptyFields: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dialog: false,
      panel: this.expandable ? null : 0,
    };
  },

  computed: {
    plainItem() {
      return extractIds(this.item);
    },

    visibleInfo() {
      return this.info.filter((i) => {
        if (i.divider) return true;
        return !(
          (this.hideEmptyFields || i.hideIfEmpty) &&
          !_get(this.item, i.field) &&
          _get(this.item, i.field) == null
        );
      });
    },
  },

  methods: {
    snakeToCamel,

    ucFirst,

    _get,

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
span {
  word-break: keep-all;
  white-space: normal;
}

.grow {
  max-width: 100%;
}
</style>
