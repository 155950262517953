<template>
  <base-table
    :canEdit="false"
    :title="$tc('models.presence', 2)"
    :items="presents"
    :headers="headers"
    :itemRoute="itemRoute"
    v-bind="tableProps"
    @deleteItem="deletePresence"
  >
    <template v-slot:form="{ close }">
      <present-form
        :baseItem="baseItem"
        @cancel="close"
        @created="(presence) => presenceCreated(presence, close)"
      />
    </template>
  </base-table>
</template>

<script>
export const PRESENTS_TABLE_FRAGMENT = gql`
  fragment presentsTable on StudentYear {
    id
    canView
    residence {
      id
      name
    }
    student {
      id
      user {
        id
        surname
        name
        complete_name
      }
    }
    pivot {
      updated_at
    }
  }
`;

const EVENT_PRESENCES_FRAGMENT = gql`
  fragment eventPresents on Event {
    id
    presents {
      ...presentsTable
    }
  }
  ${PRESENTS_TABLE_FRAGMENT}
`;

import BaseTable from "./BaseTable.vue";
import PresentForm from "../forms/PresentForm.vue";
import gql from "graphql-tag";
import moment from "moment";
import { REMOVE_SIGN } from "../../graphql/mutations";
import { playSound } from "../../maia/utils";
import { addToCacheFragmentArray, removeFromCacheFragmentArray } from "../../apollo/helpers";

export default {
  components: { BaseTable, PresentForm },
  name: "EventPresentsTable",
  props: {
    presents: { type: Array, required: true },
    tableProps: { type: Object, default: () => ({}) },
    baseItem: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      headers: [
        { value: "student.user.surname", text: this.$t("user.surname") },
        { value: "student.user.name", text: this.$t("user.name") },
        { value: "residence.name", text: this.$t("residence.name") },
        {
          value: "pivot.updated_at",
          text: this.$t("headers.signed_at"),
          type: "timestamp",
        },
      ],

      moment,
    };
  },

  methods: {
    itemRoute(item) {
      return {
        name: "UserProfileYearTab",
        hash: "#student-year",
        params: { student_year_id: item.id, id: item.student.user.id, tab: "2" },
      };
    },
    presenceCreated(present, close) {
      playSound();
      addToCacheFragmentArray(this.$apollo.provider.defaultClient, {
        fragment_info: {
          id: `Event:${this.baseItem.event.id}`,
          fragment: EVENT_PRESENCES_FRAGMENT,
          fragmentName: "eventPresents",
        },
        newElement: present,
        array_path: "presents",
      });
      close();
    },

    deletePresence(present) {
      this.$apollo
        .mutate({
          mutation: REMOVE_SIGN,
          variables: { input: { student_year_id: present.id, event_id: this.baseItem.event.id } },
          update: (store) =>
            removeFromCacheFragmentArray(store, {
              fragment_info: {
                id: `Event:${this.baseItem.event.id}`,
                fragment: EVENT_PRESENCES_FRAGMENT,
                fragmentName: "eventPresents",
              },
              array_path: "presents",
              id: present.id,
            }),
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
