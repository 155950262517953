<template>
  <base-table
    :canEdit="false"
    :title="$tc('models.subscriber', 2)"
    :items="enrolled"
    :headers="headers"
    :itemRoute="itemRoute"
    v-bind="tableProps"
    @deleteItem="unenrollStudentYear"
  >
    <template v-slot:form="{ close }">
      <enrolled-form
        :baseItem="baseItem"
        @cancel="close"
        @created="(enrolledStudentYear) => studentYearEnrolled(enrolledStudentYear, close)"
      />
    </template>
  </base-table>
</template>

<script>
export const ENROLLED_TABLE_ROW_FRAGMENT = gql`
  fragment enrolledTableRow on StudentYear {
    id
    name
    surname
    student {
      id
      user {
        id
      }
    }
    enrollment_pivot {
      updated_at
    }
  }
`;

export const ENROLLABLE_ENROLLED_FRAGMENT = gql`
  fragment enrolledTable on Enrollable {
    id
    enrolledStudentYears {
      ...enrolledTableRow
    }
  }
  ${ENROLLED_TABLE_ROW_FRAGMENT}
`;

import BaseTable from "./BaseTable.vue";
import gql from "graphql-tag";
import moment from "moment";
import { addToCacheFragmentArray, removeFromCacheFragmentArray } from "../../apollo/helpers";
import EnrolledForm from "../forms/EnrolledForm.vue";

export default {
  components: { BaseTable, EnrolledForm },
  name: "EnrolledTable",
  props: {
    enrolled: { type: Array, required: true },
    tableProps: { type: Object, default: () => ({}) },
    baseItem: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      headers: [
        { value: "surname", text: this.$t("user.surname") },
        { value: "name", text: this.$t("user.name") },
        {
          value: "enrollment_pivot.updated_at",
          text: this.$t("headers.enrolled_at"),
          type: "timestamp",
        },
      ],

      moment,
    };
  },

  methods: {
    itemRoute(item) {
      return {
        name: "UserProfileYearTab",
        hash: "#student-year",
        params: { student_year_id: item.id, id: item.student.user.id, tab: "2" },
      };
    },
    studentYearEnrolled(enrolledStudentYear, close) {
      const typename = this.baseItem.enrollable.__typename;
      addToCacheFragmentArray(this.$apollo.provider.defaultClient, {
        fragment_info: {
          id: `${typename}:${this.baseItem.enrollable.id}`,
          fragment: ENROLLABLE_ENROLLED_FRAGMENT,
          fragmentName: "enrolledTable",
        },
        newElement: enrolledStudentYear,
        array_path: "enrolledStudentYears",
      });
      close();
    },

    unenrollStudentYear(enrolledStudentYear) {
      const typename = this.baseItem.enrollable.__typename;
      const model = typename.toLowerCase();
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UnenrollStudentYearFrom${typename}($input: EnrollStudentYearTo${typename}Input!) {
              unenrollStudentYearFrom${typename}(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: { student_year_id: enrolledStudentYear.id, [`${model}_id`]: this.baseItem.enrollable.id },
          },
          update: (store) => {
            removeFromCacheFragmentArray(store, {
              fragment_info: {
                id: `${typename}:${this.baseItem.enrollable.id}`,
                fragment: ENROLLABLE_ENROLLED_FRAGMENT,
                fragmentName: "enrolledTable",
              },
              array_path: "enrolledStudentYears",
              id: enrolledStudentYear.id,
            });
          },
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
