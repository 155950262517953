<template>
  <base-form
    model="enrolled"
    @save="create()"
    :fields="fields"
    :currentValue="enrolled"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { required } from "vuelidate/lib/validators";
import { ENROLLED_TABLE_ROW_FRAGMENT } from "../tables/EnrolledTable.vue";

export default {
  components: { BaseForm },
  name: "EnrolledForm",
  props: {
    baseItem: { default: () => ({}) },
  },
  data() {
    return {
      newValue: {},
      enrolled: {},
      saving: false,
    };
  },
  computed: {
    typename() {
      return this.baseItem.enrollable.__typename;
    },
    model() {
      return this.typename.toLowerCase();
    },
    fields() {
      const path = `${this.model}.reachedStudentYears`;

      return [
        {
          name: "studentYear",
          type: "query",
          path,
          text: "full_name",
          query: gql`
            query StudentYears($id: ID!) {
              ${this.model}(id: $id) {
                id
                reachedStudentYears {
                  id
                  full_name
                }
              }
            }
          `,
          variables: () => ({ id: this.baseItem.enrollable.id }),
          label: this.$t("student.name"),
          validation: {
            required,
          },
        },
      ];
    },
    mutation() {
      return gql`
        mutation EnrollStudentYearTo${this.typename}($input: EnrollStudentYearTo${this.typename}Input!) {
          enrollStudentYearTo${this.typename}(input: $input)
          {
            id
            ...enrolledTableRow
          }
        }
      ${ENROLLED_TABLE_ROW_FRAGMENT}`;
    },
  },
  methods: {
    create() {
      this.saving = true;

      const input = { student_year_id: this.newValue.student_year_id };
      input[`${this.model}_id`] = this.baseItem.enrollable.id;

      this.$apollo
        .mutate({
          mutation: this.mutation,
          variables: {
            input,
          },
        })
        .then(({ data }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", data[`enrollStudentYearTo${this.typename}`]);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
