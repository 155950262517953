<template>
  <v-card rounded="lg" class="overflow-hidden">
    <review-form
      v-if="editing"
      @saved="editing = false"
      @cancel="editing = false"
      @created="reviewCreated"
      @deleted="reviewDeleted"
      :review_id="review_id"
      :reviewable_model="reviewable_model"
      :baseItem="{ reviewable }"
    />
    <div v-else-if="review">
      <v-card-title class="text-h5 text-capitalize"> {{ $t("event.myReview") }}</v-card-title>
      <v-divider />
      <review :review="review" hide_delete />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="editing = true">{{ $t("dialog.edit") }} </v-btn>
      </v-card-actions>
    </div>
    <div v-else>
      <v-card-title class="text-h6 text-capitalize font-weight-regular"> {{ $t("event.myReview") }} </v-card-title>
      <v-divider />
      <v-card-text class="text-center">
        <v-btn @click="editing = true" color="primary">Aggiungi recensione</v-btn>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import Review from "./Review.vue";
import ReviewForm from "../../forms/ReviewForm.vue";
export default {
  components: { ReviewForm, Review },
  props: {
    review: Object,
    reviewable: { type: Object, required: true },
  },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    review_id() {
      return this.review?.id;
    },
    reviewable_model() {
      return this.reviewable.__typename;
    },
  },
  methods: {
    reviewCreated(review) {
      this.$emit("created", review);
      this.editing = false;
    },
    reviewDeleted(review) {
      this.$emit("deleted", review);
      this.editing = false;
    },
  },
};
</script>

<style>
</style>