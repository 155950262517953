<template>
  <span class="d-flex align-center">
    <v-btn exact v-if="to" icon small link text depressed color="black" :to="to"> <v-icon>mdi-arrow-left-circle-outline</v-icon> </v-btn>
    <span class="pl-1">{{ text }}</span>
  </span>
</template>

<script>
export default {
  props: ["to", "text"]
}
</script>