import gql from "graphql-tag";
import fragments from "./fragments";

export const EVENT_SIGN_SUBSCRIPTION = gql`
  subscription NewSign($input: NewSignInput!) {
    newSign(input: $input) {
      id
      verified
      session
      qr_url
      updated_at
      studentYear {
        id
        student {
          id
          user {
            id
            name
            surname
            complete_name
          }
        }
      }
      event {
        id
      }
    }
  }
`;

export const NEW_MEAL_SUBSCRIPTION = gql`
  subscription NewMeal($input: NewMealInput!) {
    newMeal(input: $input) {
      ...meal
      type
      date
      qr_url
      residence {
        id
      }
    }
  }
  ${fragments.meal}
`;
