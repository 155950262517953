<template>
  <base-form
    model="present"
    @save="create()"
    :fields="fields"
    :currentValue="present"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { required } from "vuelidate/lib/validators";

const PUT_SIGN = require("../../graphql/mutations/PutSign.gql");

export default {
  components: { BaseForm },
  name: "PresentForm",
  props: {
    baseItem: { default: () => ({}) },
  },

  data() {
    return {
      newValue: {},
      present: {},
      saving: false,
      fields: [
        {
          name: "studentYear",
          type: "query",
          path: "event.reachedStudentYears",
          text: "full_name",
          query: gql`
            query StudentYears($id: ID!) {
              event(id: $id) {
                id
                reachedStudentYears {
                  id
                  full_name
                }
              }
            }
          `,
          variables: () => ({ id: this.baseItem.event.id, activeStatus: true }),
          label: this.$t("student.name"),
          validation: {
            required,
          },
        },
      ],
    };
  },
  methods: {
    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: PUT_SIGN,
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { putSign } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", putSign);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
