<template>
  <v-card rounded="lg" class="overflow-hidden">
    <v-card-title class="text-truncate text-h6 text-uppercase"> {{ $tc("models.review", 2) }} ({{ reviews.length }})</v-card-title>
    <v-divider />
    <v-list dense v-if="reviews.length">
      <div v-for="(review, index) in reviews" :key="review.id">
        <v-list-item>
          <review :review="review" @deleted="$emit('reviewDeleted', review)" />
        </v-list-item>
        <v-divider class="mx-4" v-if="index < reviews.length - 1"></v-divider>
      </div>
    </v-list>
    <v-card-text class="text-center" v-else>Nessuna recensione</v-card-text>
  </v-card>
</template>

<script>
import Review from "./Review.vue";
export default {
  components: { Review },
  props: {
    reviews: Array,
  },
};
</script>

<style>
</style>