<template>
  <v-container fluid>
    <v-row dense align="center" justify="start">
      <v-col cols="auto">
        <v-avatar size="36px">
          <v-img v-if="review.studentYear.avatar" aspect-ratio="1" :src="review.studentYear.avatar.link" />
          <v-img v-else aspect-ratio="1" src="@/assets/blank-avatar.png" />
        </v-avatar>
      </v-col>
      <v-col cols="auto">{{ review.studentYear.full_name }}</v-col>
      <v-spacer />
      <v-col class="font-weight-thin font-italic" cols="auto">{{ moment(review.created_at).format("LL") }}</v-col>
    </v-row>

    <v-row :class="{ 'pl-4': review.title }" align="center" justify="start" dense>
      <v-col cols="auto">
        <span class="font-weight-bold">{{ review.title }}</span>
      </v-col>
      <v-col cols="auto">
        <v-rating class="pb-1" half-increments small dense readonly :value="review.rating" />
      </v-col>
    </v-row>

    <v-row class="pl-4" dense>
      <v-col cols="auto">
        <span style="word-break: break-word; white-space: break-spaces">{{ review.comment }}</span>
      </v-col>
    </v-row>

    <v-row dense v-if="review.canDelete && !hide_delete">
      <delete-dialog v-model="deleteDialog" @confirm="deleteReview" />
      <v-spacer />
      <v-btn @click="deleteDialog = true" text fab x-small color="danger"><v-icon>mdi-delete</v-icon></v-btn>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { deleteMutationBuilder } from "../../../graphql/mutations";
import gql from "graphql-tag";
import DeleteDialog from "../../DeleteDialog.vue";

export const REVIEW_FRAGMENT = gql`
  fragment review on Review {
    id
    title
    comment
    rating
    created_at
    canDelete
    studentYear {
      id
      full_name
    }
  }
`;

export const REVIEW_MUTATION_FRAGMENT = gql`
  fragment reviewMutationFragment on Review {
    ...review
    reviewable {
      id
      rating
      myReview {
        id
      }
      ... on Event {
        course {
          id
          eventsRating
        }
      }
    }
  }
  ${REVIEW_FRAGMENT}
`;

export default {
  components: { DeleteDialog },
  name: "Review",
  props: { review: Object, hide_delete: Boolean },
  data() {
    return {
      deleteDialog: false,
    };
  },
  methods: {
    moment,

    deleteReview() {
      this.deleting = true;
      this.$apollo
        .mutate({
          mutation: deleteMutationBuilder({
            model: "Review",
            fragment: REVIEW_MUTATION_FRAGMENT,
            fragment_name: "reviewMutationFragment",
          }),
          variables: { id: this.review.id },
        })
        .then(() => {
          this.deleting = false;
          this.$emit("deleted");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>

<style></style>
