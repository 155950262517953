<template>
  <base-form
    model="review"
    @save="review_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.review.loading"
    :currentValue="review"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  >
    <template v-slot:actions="{ saving }">
      <v-btn @click="deleteDialog = true" v-if="review_id" :disabled="saving" :loading="deleting" color="danger" text>
        {{ $t("delete") }}
      </v-btn>
      <delete-dialog v-model="deleteDialog" @confirm="deleteReview" />
    </template>
  </base-form>
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import DeleteDialog from "../DeleteDialog.vue";
import { createMutationBuilder, deleteMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import { REVIEW_MUTATION_FRAGMENT } from '../education/reviews/Review.vue';

const REVIEW_FORM_FRAGMENT = gql`
  fragment reviewForm on Review {
    id
    title
    comment
    rating
    reviewable {
      id
    }
  }
`;

export default {
  components: { BaseForm, DeleteDialog },
  name: "ReviewForm",
  props: {
    review_id: {},
    reviewable_model: { type: String, required: true },
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    review_id(val) {
      if (val == null) this.review = {};
    },
  },

  apollo: {
    review: {
      query: gql`
        query Review($id: ID!) {
          review(id: $id) {
            ...reviewForm
          }
        }
        ${REVIEW_FORM_FRAGMENT}
      `,
      variables() {
        return { id: this.review_id };
      },
      skip() {
        return this.review_id == null;
      },
    },
  },

  data() {
    return {
      newValue: {},
      review: {},
      saving: false,
      deleting: false,
      deleteDialog: false,
      fields: [
        {
          name: "rating",
          cols: 12,
          type: "rating",
          validation: {
            required,
          },
          bind: {
            "half-increments": true,
          },
        },
        {
          name: "title",
          cols: 12,
          bind: {
            dense: true,
          },
        },
        {
          name: "comment",
          type: "textarea",
          cols: 12,
          bind: {
            outlined: true,
            dense: true,
          },
        },
      ],
    };
  },

  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({ model: "Review", fragment: REVIEW_MUTATION_FRAGMENT, fragment_name: "reviewMutationFragment" }),
          variables: {
            id: this.review_id,
            input: this.newValue,
          },
        })
        .then(({ data: { updateReview } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("saved", updateReview);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Review",
            fragment: REVIEW_MUTATION_FRAGMENT,
            fragment_name: "reviewMutationFragment",
            mutation: `create${this.reviewable_model}Review`,
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { [`create${this.reviewable_model}Review`]: createReview } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createReview);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    deleteReview() {
      this.deleting = true;
      this.$apollo
        .mutate({
          mutation: deleteMutationBuilder({ model: "Review", fragment: REVIEW_MUTATION_FRAGMENT, fragment_name: "reviewMutationFragment" }),
          variables: { id: this.review_id },
        })
        .then(({ data: { deleteReview } }) => {
          this.$refs.form.resetInfo();
          this.deleting = false;
          this.$emit("deleted", deleteReview);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>