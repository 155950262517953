<template>
  <v-btn
    v-if="payable.has_payments && payable.payment_link"
    block
    elevation="0"
    color="primary"
    target="_blank"
    :href="payable.payment_link"
    >{{ $t("payable.open_payment_link") }}</v-btn
  >
</template>

<script>
import gql from "graphql-tag";

export const PAYMENT_LINK_FRAGMENT = gql`
  fragment paymentLink on PayableInterface {
    id
    has_payments
    payment_link
  }
`;

export default {
  name: "PaymentLinkButton",
  props: {
    payable: { type: Object, required: true },
  },
};
</script>
