<template>
  <v-data-table
    item-key="id"
    :no-data-text="$t('no_data')"
    :headers="table_headers"
    :items="formattedItems"
    :search="search"
    locale="it-IT"
    :expanded.sync="expanded"
    v-bind="tableProps"
  >
    <template v-if="!hideHeader" v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title v-if="!searching" class="text-truncate text-h6 text-uppercase pr-2">
          {{ title.charAt(0).toUpperCase() + title.slice(1) }}
          <span v-if="showTotal">({{ items.length }})</span>
        </v-toolbar-title>
        <v-spacer v-if="!searching" />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          ref="search"
          single-line
          dense
          hide-details
          clearable
          v-if="searching"
          @blur="searchBlur"
        ></v-text-field>
        <v-btn fab depressed x-small v-if="!searching" @click="startSearch" color="primary" dark>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab depressed x-small v-if="!viewOnly" @click="editItem()" color="primary" dark class="ml-1">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <slot name="toolbar"></slot>
      </v-toolbar>

      <v-divider></v-divider>

      <slot name="top"></slot>

      <v-dialog v-model="dialog" max-width="800px" persistent>
        <slot
          name="form"
          :duplicating="duplicating"
          :selected_id="editedItem.id"
          :close="() => (dialog = false)"
        ></slot>
      </v-dialog>

      <delete-dialog v-model="dialogDelete" v-if="!viewOnly" @confirm="deleteItemConfirm" />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex flex-nowrap">
        <v-btn small icon @click="editItem(item)" v-if="canEdit">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-btn small icon @click="duplicateItem(item)" v-if="canEdit && !no_duplicates">
          <v-icon small> mdi-content-copy </v-icon>
        </v-btn>
        <v-btn small icon @click="deleteItem(item)" v-if="canDelete">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
        <v-btn icon v-if="itemRoute && itemRoute(item)" small :to="itemRoute(item)">
          <v-icon small>{{ itemRouteIcon }}</v-icon>
        </v-btn>
        <slot name="extra-actions" :item="item"></slot>
      </div>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-container>
          <slot v-bind:item="item" v-bind:headers="headers" name="expanded-item">
            Details on {{ item.__typename }}
          </slot>
        </v-container>
      </td>
    </template>

    <template v-for="header in typedFieldsFormatted" v-slot:[`item.${header.value}`]="{ header, value }">
      {{ formatters[header.type](value, header) }}
    </template>

    <template v-for="field in typedFieldsWithoutFormatter" v-slot:[`item.${field.value}`]="{ item }">
      <div v-if="field.type == 'file'" :key="field.value">
        <v-btn
          v-if="_get(item, field.value)"
          color="info"
          small
          text
          link
          target="_blank"
          :href="_get(item, field.value + '.link')"
        >
          {{ $t(`view`) }}
        </v-btn>
        <span :key="field.value" v-else>({{ $t("empty") }})</span>
      </div>
      <div v-else-if="field.type == 'autocomplete'" :key="'autocomplete-' + field.value">
        {{
          _get(item, field.value) && $t("options." + field.model + "." + field.field + "." + _get(item, field.value))
        }}
      </div>
      <div v-else-if="field.type == 'link'" :key="'link-' + field.value">
        <a :href="_get(item, field.value)" target="_blank">{{ _get(item, field.value) }}</a>
      </div>
      <slot v-else-if="field.type == 'template'" :name="field.value" v-bind:item="item">
        {{ _get(item, field.value) }}
      </slot>
    </template>
  </v-data-table>
</template>

<script>
import _get from "lodash/get";
import _set from "lodash/set";

import DeleteDialog from "../../components/DeleteDialog.vue";

import moment from "moment";
import { extractIds } from "@/apollo/helpers";
import Vue from "vue";

export const formatters = {
  timestamp: (value) => moment(value).format("lll"),
  date: (value) => moment(value).format("LL"),
  time: (value) => moment(value).format("HH:SS"),
  decimalNumber: (value, field = {}) => Number.parseFloat(value).toFixed(field.precision ?? 2),
  currency: (value) => `${Number.parseFloat(value).toFixed(2)} €`,
  duration: (value) => moment.utc(moment.duration(value, "seconds").asMilliseconds()).format("HH:mm"),
};

export default {
  name: "BaseTable",

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      duplicating: false,
      searching: false,
      editedItem: {},
      search: "",
      expanded: [],
      formatters,
    };
  },

  props: {
    tableProps: { type: Object, default: () => ({}) },
    model: String,
    items: {
      type: Array,
      required: true,
    },
    title: String,
    baseItem: {
      type: Object,
      default: () => {},
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    itemRoute: {
      type: Function,
    },
    itemRouteIcon: {
      type: String,
      default: "mdi-eye",
    },
    no_duplicates: Boolean,
  },

  components: {
    DeleteDialog,
  },

  computed: {
    table_headers() {
      let table_headers = this.headers;

      if (this.viewOnly) return table_headers;

      const ACTION_HEADER = {
        text: this.$t("headers.actions"),
        value: "actions",
        sortable: false,
      };

      return [...table_headers, ACTION_HEADER];
    },

    typedFields() {
      return this.headers.filter((header) => header.type);
    },

    typedFieldsWithoutFormatter() {
      return this.typedFields.filter((header) => this.formatters[header.type] == undefined);
    },

    typedFieldsFormatted() {
      return this.typedFields.filter((header) => this.formatters[header.type] != undefined);
    },

    duplicatingItem() {
      if (this.editedItem == {}) return null;

      // eslint-disable-next-line no-unused-vars
      const { id, ...duplicatingItem } = this.editedItem;
      return duplicatingItem;
    },

    formattedItems() {
      return this.items.map((item) => {
        let formattedItem = { ...item };
        this.typedFields.forEach((field) => {
          const formatter = this.formatters[field.type];
          if (formatter) _set(formattedItem, `formatted_${field.value}`, formatter(_get(item, field.value)));
        });
        return formattedItem;
      });
    },
  },

  methods: {
    _get,

    moment,

    editItem(item = null) {
      if (item != null) {
        this.editedItem = extractIds(item);
      } else {
        this.editedItem = {};
      }
      this.duplicating = false;
      this.dialog = true;
    },

    duplicateItem(item) {
      this.editedItem = extractIds(item);
      this.duplicating = true;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedItem = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$emit("deleteItem", this.editedItem);
    },

    save(input) {
      Object.keys(input).forEach((key) => {
        if (input[key] === this.editedItem[key] && (input[key] ?? "") === "") delete input[key];
      });

      if (input.id === -1) {
        delete input.id;
        this.$emit("createItem", input);
        this.duplicating = false;
      } else {
        this.$emit("updateItem", input);
      }
      this.dialog = false;
    },

    searchBlur() {
      if (!this.search) this.searching = false;
    },

    startSearch() {
      this.searching = true;
      Vue.nextTick(() => this.$refs.search.$refs.input.focus());
    },
  },
};
</script>
